<template>
	<!-- 预出库单 -->
	<div class="preDeliveryNote">
		<!-- 导出模板 -->
		<div id="export" style="position: fixed;top: -9999999px;">
			<div class="export-template" v-for="(item, index) in selectionList" :key="index">
				<div :id="'template-title' + index">
					<div style="line-height: 50px;font-size: 22px;" align=center>
						<strong>
							<font>{{ supplier_info.supplier_name }}配送单</font>
						</strong>
					</div>
					<div class="title-box">
						<div class="box-l">
							<div class="l-item bold">提货方式：{{ item.delivery_type == 1 ?
								'配送【' + (item.deliveryline ? item.deliveryline.title : '') + '】,运费' + item.delivery_fee +
								'元,'
								+
								(item.delivery_fee_status == 0 ? '未付' : '已付')
								: '自提' }}</div>
							<div class="l-item">
								<span>仓库：{{ item.warehouse ? item.warehouse.name : '暂无' }}</span>
								<span>配送日期：{{ item.date }}</span>
							</div>
							<div class="l-item">收货人：{{ item.contacts }}-{{ item.phone }}-{{ item.address }}</div>
						</div>
						<div class="box-r">
							{{ index + 1 }}
							<!-- <img
                                src="https://jijingsuyuan.oss-cn-chengdu.aliyuncs.com/static_easyadmin/20240626/Pnr1719394807rIKvVA.png" /> -->
						</div>
					</div>
				</div>
				<div :id="'template-table' + index">
					<table border=1 width="100%" style="border-collapse:collapse" bordercolor="#333333">
						<thead>
							<tr>
								<td width="15%" style="padding:8px">
									<div align=center><b>序号</b></div>
								</td>
								<td width="55%" style="padding:8px">
									<div align=center><b>商品名称</b></div>
								</td>
								<td width="15%" style="padding:8px">
									<div align=center><b>订单数</b></div>
								</td>
								<td width="15%" style="padding:8px">
									<div align=center><b>配送数</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(itemChil, indexChil) in item.goodsList" :key="indexChil">
								<td align=center style="padding:8px">{{ indexChil + 1 }}</td>
								<td align=center style="padding:8px">{{ itemChil.goods_name }}</td>
								<td align=center style="padding:8px">{{ itemChil.count }}</td>
								<td align=center style="padding:8px">{{ itemChil.ck_count }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- 待配商品 -->
		<div id="print_summary" style="position: fixed;top: -9999999px;">
			<div class="export-template">
				<div id="template-summary-title">
					<div style="line-height: 50px;font-size: 22px;" align=center>
						<strong>
							<!-- <font>{{ routeName ? routeName : '全部' }}-配送汇总</font> -->
							<font>待配商品汇总</font>
						</strong>
					</div>
				</div>
				<div class="title-box">
					<div class="box-l">
						<div class="l-item">
							<span>打印时间：{{ currentDateTime }}</span>
						</div>
						<div class="l-item">
							<span>配送路线：自选配送单</span>
						</div>
					</div>
				</div>
				<div id="template-summary-table">
					<table border=1 cellSpacing=0 cellPadding=1 width="100%" style="border-collapse:collapse"
						bordercolor="#333333">
						<thead>
							<tr>
								<td width="10%" style="padding:6px 0">
									<div align=center><b>序号</b></div>
								</td>
								<td width="35%" style="padding:6px 0">
									<div align=center><b>商品名称</b></div>
								</td>
								<td width="20%" style="padding:6px 0">
									<div align=center><b>商品卖点</b></div>
								</td>
								<td width="20%" style="padding:6px 0">
									<div align=center><b>自定义属性</b></div>
								</td>
								<td width="15%" style="padding:6px 0">
									<div align=center><b>数量</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in summaryTableData" :key="index">
								<td align=center style="padding:6px 0">{{ index + 1 }}</td>
								<td align=center style="padding:6px 0">{{ item.goods_name }}</td>
								<td align=center style="padding:6px 0">{{ item.goods_intro }}</td>
								<td align=center style="padding:6px 0">{{ item.goods_attr }}</td>
								<td align=center style="padding:6px 0">{{ item.count }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">配送日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.contacts" placeholder="请输入联系人姓名" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系电话:</div>
					<div class="item-input">
						<el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">地址:</div>
					<div class="item-input">
						<el-input v-model="searchForm.address" placeholder="请输入地址" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入包含商品的名称关键字" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">提货方式:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
							<el-option v-for="item in deliveryTypeList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送路线:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_line_id" placeholder="请选择">
							<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="success" @click="handlePrint" :disabled="exportDisabled">批量打印</el-button>
				<el-button @click="handleExport">导出</el-button>
				<el-button type="danger" @click="showSummaryDialog" v-loading.fullscreen.lock="fullscreenLoading"
					:disabled="selectionList.length == 0">待配商品</el-button>
				<el-button type="primary" @click="showPositionDialog"
					v-loading.fullscreen.lock="fullscreenLoading">配送点位</el-button>
				<el-button type="warning" @click="clickRoutePlan">路线规划</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" @selection-change="selectionChange">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.goodsList" style="width: 100%;border:1px solid #e6a23c">
							<el-table-column prop="goods_name" label="商品名称" align="center">
								<template slot-scope="scope">
									<span
										v-if="searchForm.goods_name && scope.row.goods_name.indexOf(searchForm.goods_name) >= 0"
										style="color:#5500ff;font-weight: 600;">{{ scope.row.goods_name }}</span>
									<span v-else>{{ scope.row.goods_name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="商品主图" align="center">
								<template slot-scope="scope">
									<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
										:preview-src-list="[scope.row.goods_pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="price" label="订单价" align="center"></el-table-column>
							<el-table-column prop="count" label="订单数量" align="center"></el-table-column>
							<el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
							<el-table-column prop="ck_count" label="配送数量" align="center"></el-table-column>
							<el-table-column prop="ck_amount" label="配送金额" align="center"></el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="下单团长" align="center">
					<template slot-scope="scope">
						<p>{{ scope.row.leader.username }}</p>
						<p> {{ scope.row.leader.phone }}</p>
					</template>
				</el-table-column>
				<el-table-column label="提货方式" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.delivery_type == 2">自提</span>
						<span v-if="scope.row.delivery_type == 1">配送</span>
					</template>
				</el-table-column>
				<el-table-column label="总运费" align="center">
					<template slot-scope="scope">
						<p>{{ scope.row.delivery_fee }}({{ scope.row.delivery_fee_status == 0 ? '未付' : '已付' }})</p>
					</template>
				</el-table-column>
				<el-table-column label="配送路线" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.deliveryline">
							<span>{{ scope.row.deliveryline.title }}</span>
						</div>
						<div v-else>未设置</div>
					</template>
				</el-table-column>
				<el-table-column label="收货人" align="center">
					<template slot-scope="scope">
						<p>{{ scope.row.contacts }}</p>
					</template>
				</el-table-column>
				<el-table-column label="收货人电话" align="center">
					<template slot-scope="scope">
						<p>{{ scope.row.phone }}</p>
					</template>
				</el-table-column>
				<el-table-column label="收货人地址" align="center">
					<template slot-scope="scope">
						<p>{{ scope.row.address }}</p>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="180">
					<template slot-scope="scope">
						<el-button type="success" size="mini" @click="handlePrint(scope.row)">打印</el-button>
						<el-button type="warning" size="mini" @click="handleAddress(scope.row)">改地址</el-button>
						<!-- <el-button type="danger" size="mini" v-if="scope.row.status == 0"
                            @click="handleStatus(scope.row, 9)">取消</el-button> -->
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 修改地址 -->
		<el-dialog title="修改地址" :visible.sync="addressDialog" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="addressForm" label-width="80px">
				<el-form-item label="收货人">
					<el-input v-model="addressForm.contacts" placeholder="请输入收货人" />
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input v-model="addressForm.phone" placeholder="请输入联系电话" />
				</el-form-item>
				<el-form-item label="详细地址">
					<el-input v-model="addressForm.address" placeholder="请输入详细地址" />
				</el-form-item>
				<el-form-item label="配送路线" v-if="addressForm.delivery_type == 1">
					<el-select v-model="addressForm.delivery_line_id" placeholder="请选择配送路线" style="width: 100%;"
						@focus="getRouteList()">
						<el-option v-for="item in addressRouteLine" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addressDialog = false">取 消</el-button>
				<el-button type="primary" @click="addressConfirmFn" v-loading.fullscreen.lock="fullscreenLoading">确
					定</el-button>
			</span>
		</el-dialog>
		<!-- 路线规划 -->
		<el-dialog title="路线规划" :visible.sync="routePlannDialog" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="routePlannForm" label-width="80px">
				<el-form-item label="配送仓库">
					<el-select v-model="routePlannForm.warehouse_id" placeholder="请选择配送仓库"
						@change="changeRoutePlanWarehouse" style="width: 100%;">
						<el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属路线" v-if="selectionList.length == 0">
					<el-select v-model="routePlannForm.delivery_line_id" placeholder="请选择所属路线" style="width: 100%;">
						<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配送日期" v-if="selectionList.length == 0">
					<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="路线计算">
					<el-radio-group v-model="routePlannForm.force">
						<el-radio label="0">不重新计算</el-radio>
						<el-radio label="1">重新计算路线</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="routePlannDialog = false">取 消</el-button>
				<el-button type="primary" @click="routePlannConfirmFn" v-loading.fullscreen.lock="fullscreenLoading">确
					定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="路线规划" :visible.sync="mapDialog" :close-on-click-modal="false" v-el-drag-dialog width="80%"
			fullscreen="true" @close="closeDialog">
			<div class="map-dialog">
				<div class="dialog-l">
					<div style="position: relative;">
						<div class="l-select">
							<p>行车路径：</p>
							<el-select v-model="routePlannType" placeholder="请选择行车路径" @change="changeRoutePlann">
								<el-option v-for="item in routePlannList" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="qrcode-range">
							<div id="qrcode" ref="qrcode" style="padding:10px">

							</div>
							<span style="padding-bottom:10px;font-size:14px">微信扫一扫</span>
						</div>
					</div>
					<div id="mapContainer" style="width:100%; height:93%"></div>
				</div>


				<div class="dialog-r">
					<el-tabs value="normalList" style="max-height:800px">
						<el-tab-pane :label="normalName" name="normalList">
							<div class="r-item">
								<div class="item-title"><span
										style="color:green;font-weight: 600;">起点</span>：{{ routePlannForm.warehouse_name }}
								</div>
							</div>
							<div class="r-item"
								v-for="(item, index) in routePlannType == 1 ? mapData.short : mapData.fast"
								:key="index">
								<div class="item-title"><span style="color:blue;font-weight: 600;">第{{ index + 1 }}站
									</span>：{{ item.contacts }}
									{{ item.phone }}
								</div>
								<div class="item-address">地址：{{ item.address }}</div>
								<div class="item-address">共 {{ item.distance }} ，大约需要 {{ item.duration }}</div>
							</div>
							<div class="r-item">
								<div class="item-title" v-if="routePlannType == 1 "><span
										style="color:red;font-weight: 600;">结束</span>：总里程
									{{ mapData.totalShortDistance }} ，共需要 {{ mapData.totalShortDuration }}
								</div>
								<div class="item-title" v-else><span style="color:red;font-weight: 600;">结束</span>：总里程
									{{ mapData.totalFastDistance }} ，共需要 {{ mapData.totalFastDuration }}
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="errName" name="errList">
							<div class="r-item" v-for="(item, index) in mapData.errList" :key="index">
								<div class="item-title"><span
										style="color:red;font-weight: 600;">第{{ index + 1 }}个</span>：{{ item.contacts }}
									{{ item.phone }}
								</div>
								<div class="item-address">地址：{{ item.address }}</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="配送点位" :visible.sync="positionDialog" :close-on-click-modal="false" v-el-drag-dialog
			width="80%" fullscreen="true" @close="closePositionDialog">
			<div class="map-dialog">
				<div id="positionContainer" style="width:100%; height:93%"></div>
			</div>
		</el-dialog>
		<el-dialog title="待配商品" :visible.sync="summaryDialog" :close-on-click-modal="false" v-el-drag-dialog width="80%"
			@close="closeSummaryDialog">
			<div class="summary-dialog">
				<el-table :data="summaryTableData" style="width: 100%" border :header-cell-style="{
					background: '#f5f7fa',
					fontWeight: 'bold',
					color: '#303133'
				}" v-loading="loading">
					<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
					<el-table-column label="商品主图" align="center">
						<template slot-scope="scope">
							<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
								:preview-src-list="[scope.row.goods_pic]">
							</el-image>
						</template>
					</el-table-column>
					<el-table-column prop="goods_intro" label="商品卖点" align="center"></el-table-column>
					<el-table-column prop="goods_attr" label="自定义属性" align="center"></el-table-column>
					<el-table-column prop="price" label="单价" sortable="" align="center"></el-table-column>
					<el-table-column prop="count" label="订单数量" sortable="" align="center"></el-table-column>
					<el-table-column prop="amount" label="订单金额" sortable="" align="center"></el-table-column>
					<el-table-column prop="ck_count" label="配送数量" sortable="" align="center"></el-table-column>
					<el-table-column prop="ck_amount" label="配送金额" sortable="" align="center"></el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">

				<el-button type="success" @click="handlePrintSummary" v-loading.fullscreen.lock="fullscreenLoading">打
					印</el-button>
				<el-button @click="handleExportSummary" v-loading.fullscreen.lock="fullscreenLoading">导 出</el-button>
				<el-button type="info" @click="closeSummaryDialog">关 闭</el-button>
			</span>
		</el-dialog>
	</div>

</template>

<script>
	import QRCode from 'qrcodejs2'
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				currentDateTime: this.getCurrentDateTime(),
				imageUrl: "https://static.tuanhuo.howtec.cc/images/logo.png",
				normalName: "配送站点",
				errName: "异常地址",
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				orderDate: '',
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				warehouseList: [], //仓库
				routeList: [], //配送路线
				// 供应商信息
				supplier_info: {},
				// 选择表格的数据
				selectionList: [],
				exportDisabled: false, //打印禁用
				operationDisabled: false, //批量确认、取消禁用
				// 路线规划
				mapData: {
					short: [],
					fast: []
				},
				routePlannForm: {
					warehouse_name: "",
					delivery_type: 2,
					begin: '',
					end: '',
					force: '0',
				},
				routePlannDialog: false,
				mapDialog: false,
				routePlannType: 1,
				routePlannList: [{
						id: 1,
						name: '最短距离'
					},
					{
						id: 2,
						name: '最快时间'
					}
				],
				fullscreenLoading: false,
				mapKey: "",
				qrcode: null,
				positionDialog: false,
				summaryDialog: false,
				summaryTableData: [],
				//修改地址
				addressForm: {},
				addressDialog: false,
				addressRouteLine: [],
			}
		},
		mounted() {
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			// 列表
			this.getList()
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			this.routePlannForm.begin = this.dateVal[0]
			this.routePlannForm.end = this.dateVal[1]
			this.routePlannForm.force = '0'
			// 路线
			this.getRouteList()
			// 仓库
			this.getWarehouse()
		},
		methods: {
			handleAddress(item) {
				this.addressForm = {
					contacts: item.contacts,
					phone: item.phone,
					address: item.address,
					delivery_line_id: item.delivery_line_id,
					delivery_type: item.delivery_type,
					pre_ckd_ids: item.pre_ckd_ids,
				}
				this.addressRouteLine.push({
					id: 0,
					title: "未设置",
				})
				this.addressDialog = true;
			},
			addressConfirmFn() {
				this.$http.post(`erp/v1/preck/modify_address`, {
					...this.addressForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success(msg)
						this.addressForm = {}
						this.addressDialog = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			showSummaryDialog() {
				let preCkdIds = "";
				this.selectionList.forEach(item => {
					preCkdIds = preCkdIds + item.pre_ckd_ids + ","
				})
				this.summaryDialog = true
				this.$http.post(`erp/v1/preck/summary`, {
					pre_ckd_ids: preCkdIds,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.summaryTableData = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			handleExportSummary() {
				let preCkdIds = "";
				this.selectionList.forEach(item => {
					preCkdIds = preCkdIds + item.pre_ckd_ids + ","
				})
				this.$http.post(`erp/v1/preck/summary`, {
					pre_ckd_ids: preCkdIds,
					export: 1,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url)
					} else {
						this.$message.error(msg);
					}
				});
			},
			closeSummaryDialog() {
				this.summaryDialog = false
			},
			handlePrintSummary() {
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById("print_summary")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
			showPositionDialog() {
				let preCkdIds = "";
				this.selectionList.forEach(item => {
					preCkdIds = preCkdIds + item.pre_ckd_ids + ","
				})

				this.$http.post(`/erp/v1/preck/lst_address_lng_lat`, {
					'pre_ckd_ids': preCkdIds,
					...this.searchForm,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.fullscreenLoading = false;
						this.positionDialog = true
						let addressData = data.addressList
						let mapGeometries = []
						let warehouse = {
							lat: "",
							lng: ""
						}
						this.mapKey = data.mapKey

						addressData.forEach((item, index) => {
							mapGeometries.push({
								styleId: 'marker',
								position: new TMap.LatLng(item.lat, item.lng),
								content: item.contacts
							})
							warehouse.lat = item.warehouse.lat
							warehouse.lng = item.warehouse.lng

						})

						// 地图
						this.$nextTick(() => {
							let mapPosition = new TMap.Map('positionContainer', {
								center: new TMap.LatLng(warehouse.lat, warehouse.lng),
								zoom: 12,
								disableDefaultUI: true,
							});
							var marker = new TMap.MultiMarker({
								id: 'marker-layer1',
								map: mapPosition,
								styles: {
									marker: new TMap.MarkerStyle({
										width: 20,
										height: 28,
										direction: 'top',
									})

								},
								geometries: mapGeometries,
							});
						})

					} else {
						this.$message.error(msg);
					}
				});


			},
			closePositionDialog() {
				this.positionDialog = false
			},
			GenerateQRCode() {
				if (this.qrcode == null) {
					this.qrcode = new QRCode(this.$refs.qrcode, {
						width: 128, // 二维码宽度，单位像素
						height: 128, // 二维码高度，单位像素
						text: "https://tuanhuo.howtec.cc/driving_line?key=" + this.mapData.driverKey + "&expire=" +
							this.mapData.driverKeyExpire, // 生成二维码的链接
					})
				} else {
					this.qrcode.makeCode("https://tuanhuo.howtec.cc/driving_line?key=" + this.mapData.driverKey +
						"&expire=" +
						this.mapData.driverKeyExpire)
				}

			},

			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			getList() {
				this.$http.post(`/erp/v1/preck/lst_address`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选择表格数据
			selectionChange(e) {
				this.selectionList = e
				// // 打印
				// const exportArr = this.selectionList.every(i => {
				//     return i.status === 1
				// })
				// if (exportArr) {
				//     this.exportDisabled = false
				// } else {
				//     this.exportDisabled = true
				// }
				// 批量确认、取消
				const operationArr = this.selectionList.every(i => {
					return i.status === 0
				})
				if (operationArr) {
					this.operationDisabled = false
				} else {
					this.operationDisabled = true
				}
			},
			// 打印
			handlePrint(item) {
				if (item.pre_ckd_ids) {
					this.selectionList = []
					this.selectionList.push(item)
				} else {
					if (this.selectionList.length <= 0) {
						this.$message.error('请选择导出数据');
						return
					}
				}
				setTimeout(() => {
					var LODOP = getLodop();
					var totalPrint = 0
					for (var i = 0; i < this.selectionList.length; i++) {
						totalPrint++
						var titleStyle =
							"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r{font-size:20px} .box-r img {width: 100px;height: 100px;}</style>"
						LODOP.ADD_PRINT_HTM(0, "5%", "90%", 140, titleStyle + document.getElementById(
							"template-title" + i).innerHTML);
						var strStyle =
							"<style> table,td,th {border-width: 1px;border-style: solid;border-collapse: collapse;}</style>"
						LODOP.ADD_PRINT_TABLE(140, "5%", "90%", 800, strStyle + document.getElementById(
							"template-table" + i).innerHTML);
						LODOP.NewPageA();
						if (totalPrint == this.selectionList.length) { // 如果已经是最后一份，则终止此循环
							LODOP.PREVIEW()
						}
					}
					this.getList()
					this.selectionList = []
				}, 500);
			},
			// 导出
			handleExport() {
				this.$http.post(`/erp/v1/preck/lst_address`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
						this.addressRouteLine = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouse() {
				this.$http.post(`erp/v1/warehouse/lst`, {
					pageNum: 100
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.warehouseList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			//选中路线规划的起始点
			changeRoutePlanWarehouse(id) {
				let that = this
				this.warehouseList.forEach((item, index) => {
					if (item.id == id) {
						that.routePlannForm.warehouse_name = item.name
					}
				})

			},
			clickRoutePlan() {
				this.routePlannDialog = true
			},
			// 路线规划确定
			routePlannConfirmFn() {
				let preCkdIds = "";
				this.selectionList.forEach(item => {
					preCkdIds = preCkdIds + item.pre_ckd_ids + ","
				})

				if (!this.routePlannForm.warehouse_id) {
					this.$message.error('请选择配送仓库');
					return
				}

				if (preCkdIds == "" && !this.routePlannForm.delivery_line_id) {
					this.$message.error('请选择所属路线');
					return
				}
				this.fullscreenLoading = true;
				this.routePlannForm.begin = this.dateVal[0]
				this.routePlannForm.end = this.dateVal[1]
				this.$http.post(`/erp/v1/preck/lst_map`, {
					pre_ckd_ids: preCkdIds,
					...this.routePlannForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.fullscreenLoading = false;
						this.routePlannDialog = false
						this.mapDialog = true
						this.mapData = data
						this.mapKey = data.mapKey


						let routePlannArr = []
						if (this.routePlannType == 1) {
							routePlannArr = this.mapData.short
						} else {
							routePlannArr = this.mapData.fast
						}
						this.normalName = "配送站点（" + data.short.length + "）"
						this.errName = "异常地址（" + data.errList.length + "）"
						// 结束位置
						let end = routePlannArr[routePlannArr.length - 1]
						// 途经点
						let passingPoint = routePlannArr.map(i => {
							return `${i.lat}` + ',' + `${i.lng};`;
						}).join('');
						passingPoint = passingPoint.substring(0, passingPoint.lastIndexOf(';'));
						// 地图轨迹
						let geometries = []
						//起点
						geometries.push({
							styleId: 'start',
							position: new TMap.LatLng(this.mapData.start.lat, this.mapData.start
								.lng),
							content: this.routePlannForm.warehouse_name
						})
						routePlannArr.forEach((item, index) => {
							if (index < routePlannArr.length - 1) {
								geometries.push({
									styleId: 'marker',
									position: new TMap.LatLng(item.lat, item.lng),
									content: '第' + (index + 1) + '站'
								})
							}

						})
						//终点
						geometries.push({
							styleId: 'end',
							position: new TMap.LatLng(routePlannArr[routePlannArr.length - 1].lat,
								routePlannArr[routePlannArr.length - 1].lng),
							content: '第' + (routePlannArr.length) + '站'
						})
						// 地图
						this.$nextTick(() => {
							this.GenerateQRCode()

							window.map = new TMap.Map('mapContainer', {
								center: new TMap.LatLng(this.mapData.start.lat, this.mapData.start
									.lng), //自己的定位纬度 经度,
								zoom: 12,
								disableDefaultUI: true,
							});
							var url =
								`https://apis.map.qq.com/ws/direction/v1/driving/?from=${this.mapData.start.lat},${this.mapData.start.lng}&to=${end.lat},${end.lng}&output=jsonp&waypoints=${passingPoint}&callback=cb&key=${this.mapKey}`;
							//发起JSONP请求，获取路线规划结果
							this.jsonp_request(url);
							var marker = new TMap.MultiMarker({
								id: 'marker-layer',
								map: map,
								styles: {
									marker: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/tujing.png'
									}),
									start: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/start.png'
									}),
									end: new TMap.MarkerStyle({
										width: 28,
										height: 28,
										direction: 'top',
										src: 'https://static.tuanhuo.howtec.cc/miniprogram/common/end.png'
									}),
								},
								geometries: geometries,
							});
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			jsonp_request(url) {
				var script = document.createElement('script');
				script.src = url;
				document.body.appendChild(script);
			},
			// 切换路线轨迹
			changeRoutePlann(e) {
				window.map.destroy()
				this.routePlannType = e
				this.routePlannConfirmFn()
			},
			// 关闭地图弹窗
			closeDialog() {
				window.map.destroy()
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.map-dialog {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;

		.dialog-l {
			width: 80%;
			height: 100%;

			.l-select {
				display: flex;
				align-items: center;
				margin-bottom: 10px;
			}
		}

		.dialog-r {
			width: 18%;
			display: flex;
			flex-direction: column;
			overflow-y: auto;

			.r-item {
				margin-bottom: 10px;
			}
		}
	}

	.qrcode-range {
		left: 20px;
		top: 80px;
		position: absolute;
		display: flex;
		background-color: #fff;
		z-index: 999;
		color: #000;
		flex-direction: column;
		text-align: center;
	}
</style>